import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload,
  faEye,
  faArrowLeft,
  faArrowRight,
  faFileExcel,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import { v4 as uuid } from 'uuid';

const Test3 = ({ vkTourDests }: any) => {
  const [chosenList, setChosenList] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const [resetKey, setResetKey] = useState(uuid());

  const handleFileUpload = async (event: any) => {
    const file = event.target.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      try {
        const data = JSON.parse(e.target.result);

        let keys = Object.keys(data);
        let key = keys[0];
        const { chosen } = data[key];

        for (let i = 0; i < chosen?.length; i++) {
          let found = vkTourDests.find((item: any) => item?.id == chosen[i]?.vkTourDestId);

          if (found) {
            chosen[i].detail = found;
          }
        }
        setChosenList(chosen);
        event.target.value = '';
      } catch (error) {
        console.error('Error parsing JSON file:', error);
      }
    };

    reader.readAsText(file);
  };

  const handlePageChange = (pageNumber: number) => {
    setResetKey(uuid());
    if (pageNumber > 0 && pageNumber <= Math.ceil(chosenList.length / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const openModal = (item: any) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };

  const handleRatingChange = (event: any) => {
    const updatedRating = event.target.value;
    setSelectedItem({ ...selectedItem, rating: updatedRating });

    const updatedList = chosenList.map((item: any) =>
      item.detail.id === selectedItem.detail.id ? { ...item, rating: updatedRating } : item
    );
    setChosenList(updatedList);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedItems = chosenList?.slice(startIndex, startIndex + itemsPerPage);

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(chosenList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ChosenList');
    XLSX.writeFile(wb, 'chosen_list.xlsx');
  };

  const handleUpload = async (event: any) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      try {
        const wb = XLSX.read(e.target.result, { type: 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws) as any;

        for (let i = 0; i < data?.length; i++) {
          let found = vkTourDests.find((item: any) => item?.id == data[i]?.vkTourDestId);

          if (found) {
            data[i].detail = found;
          }
        }

        setChosenList(data);
      } catch (error) {
        console.error('Error parsing Excel file:', error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const ratings = {
    1: 'Poor quality, Significantly different from the reference text.',
    2: 'Fair quality, Some differences from the reference text.',
    3: 'Good quality, Minor differences from the reference text.',
    4: 'Very good quality, Nearly identical to the reference text.',
    5: 'Excellent quality, Indistinguishable from the reference text.',
  };

  const [mos, setMOS] = useState<number | null>(null); // Mean Opinion Score
  const [confidenceInterval, setConfidenceInterval] = useState<[number, number] | null>(null); // Confidence Interval

  // Function to calculate MOS and confidence interval
  const calculateMetrics = () => {
    // Check if any item has no rating

    if (chosenList?.length <= 0) {
      alert('upload a file!');
      return;
    }

    if (
      chosenList.some(
        (item: any) =>
          item?.rating === undefined || item?.rating === null || item?.rating?.length <= 0
      )
    ) {
      alert('Some items have no ratings. Please rate all items before calculating metrics.');
      return;
    }

    // Extract ratings from the list of items
    const ratings = chosenList.map((item: any) => parseInt(item.rating));

    // Calculate Mean Opinion Score (MOS)
    const sum = ratings.reduce((acc: any, rating: any) => acc + rating, 0);
    const meanRating = sum / ratings.length;
    setMOS(meanRating);

    // Bootstrap Confidence Interval
    const nBootstrapSamples = 1000; // Number of bootstrap samples
    const mosSamples = [];

    // Perform bootstrapping
    for (let i = 0; i < nBootstrapSamples; i++) {
      const bootstrapSample = [];
      for (let j = 0; j < ratings.length; j++) {
        const randomIndex = Math.floor(Math.random() * ratings.length);
        bootstrapSample.push(ratings[randomIndex]);
      }
      const bootstrapMOS =
        bootstrapSample.reduce((acc, rating) => acc + rating, 0) / bootstrapSample.length;
      mosSamples.push(bootstrapMOS);
    }

    // Calculate confidence interval
    const alpha = 0.95; // Confidence level
    const lowerQuantile = (1 - alpha) / 2;
    const upperQuantile = 1 - lowerQuantile;
    const sortedMOS = mosSamples.sort((a, b) => a - b);
    const lowerIndex = Math.floor(lowerQuantile * nBootstrapSamples);
    const upperIndex = Math.floor(upperQuantile * nBootstrapSamples);
    const confidenceInterval: [number, number] = [sortedMOS[lowerIndex], sortedMOS[upperIndex]];
    setConfidenceInterval(confidenceInterval);
  };

  const [loaded, setLoaded] = useState<any>([]);

  const handleLoad = async (urlData: any) => {
    try {
      if (loaded?.includes(urlData)) {
      } else {
        setLoaded((prev: any) => {
          return [...prev, urlData];
        });
      }
    } catch (e) {}
  };

  const [showUnratedList, setShowUnratedList] = useState(false);

  // Function to toggle display of unrated items list
  const toggleUnratedList = () => {
    setShowUnratedList(!showUnratedList);
  };

  // Filter unrated items
  const unratedItems = chosenList?.filter((item: any) => !item.rating);

  // State variables for unrated items list pagination
  const [unratedCurrentPage, setUnratedCurrentPage] = useState(1);
  const [unratedInputPage, setUnratedInputPage] = useState<number | string>(1);

  // Function to handle pagination change for unrated items list
  const handleUnratedPageChange = (direction: 'prev' | 'next' | 'input') => {
    if (direction === 'prev' && unratedCurrentPage > 1) {
      setUnratedCurrentPage(unratedCurrentPage - 1);
      setUnratedInputPage(unratedCurrentPage - 1);
    } else if (
      direction === 'next' &&
      unratedCurrentPage < Math.ceil(unratedItems.length / itemsPerPage)
    ) {
      setUnratedCurrentPage(unratedCurrentPage + 1);
      setUnratedInputPage(unratedCurrentPage + 1);
    } else if (direction === 'input') {
      const pageNumber = Number(unratedInputPage);
      if (pageNumber >= 1 && pageNumber <= Math.ceil(unratedItems.length / itemsPerPage)) {
        setUnratedCurrentPage(pageNumber);
      }
    }
  };

  return (
    <div>
      <div className="p-4 bg-white rounded-md shadow-md mb-6 flex justify-between items-center">
        <div className="flex items-center justify-center gap-4">
          <label className="flex items-center justify-center px-6 py-3 bg-blue-500 text-white rounded-md cursor-pointer hover:bg-blue-600">
            <FontAwesomeIcon icon={faUpload} className="h-6 w-6 mr-2" />
            <span>Browse</span>
            <input type="file" accept=".json" onChange={handleFileUpload} className="hidden" />
          </label>
        </div>
        <button
          onClick={handleDownload}
          className="flex items-center justify-center px-6 py-3 bg-green-500 text-white rounded-md cursor-pointer hover:bg-green-600"
          disabled={!chosenList}
        >
          <FontAwesomeIcon icon={faFileExcel} className="h-6 w-6 mr-2" />
          <span>Download as Excel</span>
        </button>
        <label className="flex items-center justify-center px-6 py-3 bg-yellow-500 text-white rounded-md cursor-pointer hover:bg-yellow-600">
          <FontAwesomeIcon icon={faUpload} className="h-6 w-6 mr-2" />
          <span>Upload Excel</span>
          <input type="file" accept=".xlsx, .xls" onChange={handleUpload} className="hidden" />
        </label>
      </div>

      {chosenList && (
        <div className="p-4 bg-white rounded-md shadow-md mb-6">
          <div>
            {/* Icon to display unrated items count and list */}
            <div className="relative inline-block" onClick={toggleUnratedList}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="xl"
                className="text-[black] cursor-pointer"
              />
              {unratedItems.length > 0 && (
                <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                  {unratedItems.length}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center flex-col my-[10px]">
            {/* Button to trigger calculation */}
            <button
              onClick={calculateMetrics}
              className="bg-[black] text-[white] rounded-[7px] py-[5px] px-[5px]"
            >
              Calculate MOS and Bootstrap Confidence Interval
            </button>

            {/* Display MOS and confidence interval */}
            {mos !== null && confidenceInterval !== null && (
              <div>
                {/* Display Mean Opinion Score (MOS) */}
                <p>
                  <strong>Mean Opinion Score (MOS):</strong> {mos.toFixed(2)}
                  {/* Explanation of MOS */}
                  <span className="text-gray-500"></span>
                </p>
                {/* Display Bootstrap Confidence Interval */}
                <p>
                  <strong>Bootstrap Confidence Interval:</strong> [
                  {confidenceInterval[0].toFixed(2)}, {confidenceInterval[1].toFixed(2)}]
                  {/* Explanation of Confidence Interval */}
                  <span className="text-gray-500"></span>
                </p>
              </div>
            )}
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse">
              <thead className="bg-gray-200 sticky top-0">
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Score</th>
                  <th className="border px-4 py-2">Image</th>
                  <th className="border px-4 py-2">Rating</th>
                  <th className="border px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedItems.map((item: any, index: number) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="border px-4 py-2">{item?.detail?.id}</td>
                    <td className="border px-4 py-2">{item.score?.toFixed(2)}</td>
                    <td
                      className="border px-4 py-2 grid grid-cols-5"
                      style={{ position: 'relative' }}
                    >
                      {item?.detail?.images[0]?.url ? (
                        <div className="col-start-2 col-span-3 p-4">
                          <img
                            src={item?.detail?.images[0]?.url}
                            alt={item?.detail?.title}
                            // className="max-h-[50%] max-w-[50%] object-cover"
                            className="w-full h-full object-cover"
                            style={{
                              display: loaded?.includes(item?.detail?.images[0]?.url)
                                ? 'block'
                                : 'none',
                            }}
                            onLoad={() => handleLoad(item?.detail?.images[0]?.url)}
                          />
                        </div>
                      ) : (
                        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-blue-200 to-blue-100 animate-pulse"></div>
                      )}
                      {loaded?.includes(item?.detail?.images[0]?.url) ? (
                        <></>
                      ) : (
                        <>
                          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-blue-200 to-blue-100 animate-pulse"></div>
                        </>
                      )}
                    </td>
                    <td className="border px-4 py-2">{item.rating}</td>
                    <td className="text-center border px-4 py-2">
                      <button
                        className="text-blue-500 hover:text-blue-700 focus:outline-none"
                        onClick={() => openModal(item)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-4">
            <button
              className="mr-2 px-3 py-1 rounded-md bg-blue-500 text-white focus
              hover
              disabled
              disabled
              "
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <span className="mx-2">
              {currentPage} / {Math.ceil(chosenList.length / itemsPerPage)}
            </span>
            <input
              type="number"
              className="mx-2 px-2 py-1 border border-gray-300 rounded-md w-16"
              value={currentPage}
              onChange={(e) => handlePageChange(Number(e.target.value))}
              min="1"
              max={Math.ceil(chosenList.length / itemsPerPage)}
            />
            <button
              className="ml-2 px-3 py-1 rounded-md bg-blue-500 text-white focus
              hover
              disabled
              disabled
              "
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(chosenList.length / itemsPerPage)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      )}
      {/* Modal for detailed view */}
      {showModal && selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-3/4 max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Item Details</h2>
              <button
                onClick={closeModal}
                className="text-red-500 text-2xl font-bold hover:text-red-700 focus:outline-none"
              >
                X
              </button>
            </div>
            <div>
              <p>
                <strong>ID:</strong> {selectedItem?.detail?.id}
              </p>
              <p>
                <strong>Title:</strong> {selectedItem?.detail?.title}
              </p>

              <p>
                <strong>Rating:</strong>
                <input
                  type="number"
                  value={selectedItem.rating}
                  onChange={handleRatingChange}
                  className="border border-gray-300 rounded-md p-1 ml-2 w-20"
                  min="0"
                  max="5"
                  step="0.1"
                />
              </p>
              <img
                src={selectedItem?.detail?.images[0]?.url}
                alt={selectedItem?.detail?.title}
                className="mt-2 w-full object-cover"
              />
            </div>
          </div>
        </div>
      )}

      {showUnratedList && unratedItems.length > 0 && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-md shadow-md w-3/4 max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Unrated Items ({unratedItems.length})</h2>
              <button
                onClick={toggleUnratedList}
                className="text-red-500 text-[20px] font-[700] hover:text-red-700 focus:outline-none"
              >
                X
              </button>
            </div>
            <div>
              <ul>
                {unratedItems
                  ?.slice(
                    (unratedCurrentPage - 1) * itemsPerPage,
                    unratedCurrentPage * itemsPerPage
                  )
                  ?.map((item: any, index: any) => (
                    <li key={index}>
                      {item?.detail?.title} (Page{' '}
                      {Math.ceil((chosenList?.indexOf(item) + 1) / itemsPerPage)})
                    </li>
                  ))}
              </ul>
            </div>
            <div className="flex justify-center mt-4">
              <button
                className="mr-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                onClick={() => handleUnratedPageChange('prev')}
                disabled={unratedCurrentPage === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <input
                type="number"
                value={unratedInputPage}
                onChange={(e) => setUnratedInputPage(e.target.value)}
                onBlur={() => handleUnratedPageChange('input')}
                className="mx-2 px-2 py-1 border rounded-md w-16"
                min="1"
                max={Math.ceil(unratedItems.length / itemsPerPage)}
              />
              <button
                className="px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                onClick={() => handleUnratedPageChange('input')}
              >
                Go
              </button>
              <button
                className="ml-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                onClick={() => handleUnratedPageChange('next')}
                disabled={unratedCurrentPage === Math.ceil(unratedItems.length / itemsPerPage)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Test3;
