import React, { useEffect, useState } from 'react';
import { default as htmlReactParser, attributesToProps } from 'html-react-parser';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faCopy,
  faExclamationTriangle,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { api } from '../../../../../../plugins/axios';

const Test2 = ({ vkTourDests }: any) => {
  const [data, setData] = useState<any>([]);
  const [sList, setSList] = useState<any>([]);
  const [copiedRatings, setCopiedRatings] = useState<number[]>([]);

  useEffect(() => {
    api.get('/admin/ai/tests/test2').then((res) => {
      if (res?.data?.items) {
        const newData = res?.data?.items
          ?.filter((obj: any) => obj?.recommVkTourDests?.length > 0)
          ?.map((item: any) => ({
            ...item,
            recommVkTourDests: item.recommVkTourDests?.map((nestedItem: any) => ({
              ...nestedItem,
              rating: nestedItem?.rating ?? 0,
            })),
          }));

        setSList(res?.data?.items);
        setData(newData);
      }
    });
  }, []);

  const handleRatingChange = (vktdId: string, rating: number) => {
    // Ensure the rating is between 1 and 5
    if (rating >= 1 && rating <= 5) {
      setData((prevData: any) =>
        prevData?.map((obj: any) => ({
          ...obj,
          recommVkTourDests: obj?.recommVkTourDests?.map((item: any) =>
            item.vktdId === vktdId ? { ...item, rating } : item
          ),
        }))
      );
    }
  };

  const [modalContent, setModalContent] = useState<any>('');
  const [showModal, setShowModal] = useState(false);

  const handleClick = (content: any) => {
    setModalContent(content);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent('');
  };

  const downloadExcel = () => {
    const excelData = [] as any;

    data?.forEach((item: any) => {
      item?.recommVkTourDests?.forEach((recItem: any, recIndex: any) => {
        excelData.push({
          sId: item.id,
          rating: recItem.rating,
          score: recItem.score,
          vktdId: recItem.vktdId,
        });
      });
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  const [handleFileUploadStatus, setHandleFileUploadStatus] = useState(false);

  const handleFileUpload = (event: any) => {
    setHandleFileUploadStatus(true);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      const parsedData = [];
      for (let i = 0; i < json.length; i++) {
        const item = json[i] as any;
        const parentId = item.sId;
        const existingItemIndex = parsedData.findIndex((dataItem: any) => dataItem.id === item.sId);

        let foundVkTourDest = vkTourDests?.find((obj: any) => obj?.id == item.vktdId);
        let foundS = sList?.find((obj: any) => obj?.id == item.sId);

        if (existingItemIndex === -1) {
          parsedData.push({
            id: parentId,
            content: foundS?.content,
            recommVkTourDests: [
              {
                rating: item.rating,
                score: item.score,
                vktdId: item.vktdId,
                details: foundVkTourDest,
              },
            ],
          });
        } else {
          parsedData[existingItemIndex].recommVkTourDests.push({
            rating: item.rating,
            score: item.score,
            vktdId: item.vktdId,
            details: foundVkTourDest,
          });
        }
      }

      setData(parsedData);
      event.target.value = '';
      setHandleFileUploadStatus(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const [showUnratedList, setShowUnratedList] = useState(false);

  // Function to toggle display of unrated items list
  const toggleUnratedList = () => {
    setShowUnratedList(!showUnratedList);
  };

  const [itemsPerPage] = useState(5);

  // Filter unrated items
  const unratedItems = data?.filter((item: any) => !item.rating);

  // State variables for unrated items list pagination
  const [unratedCurrentPage, setUnratedCurrentPage] = useState(1);
  const [unratedInputPage, setUnratedInputPage] = useState<number | string>(1);

  // Function to handle pagination change for unrated items list
  const handleUnratedPageChange = (direction: 'prev' | 'next' | 'input') => {
    if (direction === 'prev' && unratedCurrentPage > 1) {
      setUnratedCurrentPage(unratedCurrentPage - 1);
      setUnratedInputPage(unratedCurrentPage - 1);
    } else if (
      direction === 'next' &&
      unratedCurrentPage < Math.ceil(unratedItems.length / itemsPerPage)
    ) {
      setUnratedCurrentPage(unratedCurrentPage + 1);
      setUnratedInputPage(unratedCurrentPage + 1);
    } else if (direction === 'input') {
      const pageNumber = Number(unratedInputPage);
      if (pageNumber >= 1 && pageNumber <= Math.ceil(unratedItems.length / itemsPerPage)) {
        setUnratedCurrentPage(pageNumber);
      }
    }
  };

  const [mos, setMOS] = useState<number | null>(null); // Mean Opinion Score
  const [confidenceInterval, setConfidenceInterval] = useState<[number, number] | null>(null); // Confidence Interval

  // Function to calculate MOS and confidence interval
  const calculateMetrics = () => {
    // Check if any item has no rating

    if (data?.length <= 0) {
      alert('upload a file!');
      return;
    }

    for (let i = 0; i < data?.length; i++) {
      if (
        data[i]?.recommVkTourDests.some(
          (item: any) =>
            item?.rating === undefined || item?.rating === null || item?.rating?.length <= 0
        )
      ) {
        alert('Some items have no ratings. Please rate all items before calculating metrics.');
        return;
      }
    }

    // Extract ratings from the list of items
    let ratings = [] as any; // data.map((item: any) => parseInt(item.rating));

    for (let i = 0; i < data?.length; i++) {
      let tempRatings = data[i]?.recommVkTourDests?.map((item: any) => parseInt(item.rating));
      ratings = [...ratings, ...tempRatings];
    }

    // Calculate Mean Opinion Score (MOS)
    const sum = ratings.reduce((acc: any, rating: any) => acc + rating, 0);
    const meanRating = sum / ratings.length;
    setMOS(meanRating);

    // Bootstrap Confidence Interval
    const nBootstrapSamples = 1000; // Number of bootstrap samples
    const mosSamples = [];

    // Perform bootstrapping
    for (let i = 0; i < nBootstrapSamples; i++) {
      const bootstrapSample = [];
      for (let j = 0; j < ratings.length; j++) {
        const randomIndex = Math.floor(Math.random() * ratings.length);
        bootstrapSample.push(ratings[randomIndex]);
      }
      const bootstrapMOS =
        bootstrapSample.reduce((acc, rating) => acc + rating, 0) / bootstrapSample.length;
      mosSamples.push(bootstrapMOS);
    }

    // Calculate confidence interval
    const alpha = 0.95; // Confidence level
    const lowerQuantile = (1 - alpha) / 2;
    const upperQuantile = 1 - lowerQuantile;
    const sortedMOS = mosSamples.sort((a, b) => a - b);
    const lowerIndex = Math.floor(lowerQuantile * nBootstrapSamples);
    const upperIndex = Math.floor(upperQuantile * nBootstrapSamples);
    const confidenceInterval: [number, number] = [sortedMOS[lowerIndex], sortedMOS[upperIndex]];
    setConfidenceInterval(confidenceInterval);
  };

  const [chosenDetailsModal, setChosenDetailsModal] = useState<any>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const handleOpenDetailsModal = (chosenDetails: any) => {
    setChosenDetailsModal(chosenDetails);
    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded shadow-lg relative">
              <button className="absolute top-2 right-2 text-xl font-bold" onClick={closeModal}>
                X
              </button>
              <div id="modal-detail" className="max-h-96 overflow-y-auto">
                {modalContent?.content ? htmlReactParser(modalContent?.content) : null}
              </div>
            </div>
          </div>
        )}
        {isDetailsModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded shadow-lg relative" style={{ maxWidth: '500px' }}>
              <button
                className="absolute top-2 right-2 text-xl font-bold"
                onClick={handleCloseDetailsModal}
              >
                X
              </button>
              <div id="modal-detail" className="max-h-96 overflow-y-auto">
                {chosenDetailsModal ? htmlReactParser(chosenDetailsModal) : null}
              </div>
            </div>
          </div>
        )}

        <div>
          <h2 className="text-lg font-bold">Ratings Management</h2>
          {/* <p className="text-gray-600">Total Ratings: {copiedRatings.length}</p> */}
        </div>
        <div className="flex items-center text-red-500">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          <span>Please ensure ratings are between 1 and 5.</span>
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4 p-4 bg-gray-100 rounded-md shadow-md">
        <label
          className={`px-4 py-2 bg-blue-600 text-white rounded-md ${
            handleFileUploadStatus ? 'bg-gray-400 cursor-not-allowed' : ''
          }`}
        >
          Upload File
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} className="hidden" />
        </label>
        <button
          disabled={handleFileUploadStatus}
          onClick={downloadExcel}
          className={`px-6 py-2 bg-green-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 ${
            handleFileUploadStatus ? 'bg-gray-400 cursor-not-allowed' : ''
          }`}
        >
          Download Excel
        </button>
      </div>

      {handleFileUploadStatus ? (
        <>
          <div className="flex justify-center items-center m-[10px]">
            <div
              className="w-[35px] h-[35px] border-[7px] border-t-0 rounded-full animate-spin"
              style={{ borderColor: 'transparent transparent #FF3D00 transparent' }}
            ></div>
          </div>
        </>
      ) : (
        <>
          {showUnratedList && unratedItems.length > 0 && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-md shadow-md w-3/4 max-w-lg">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Unrated Items ({unratedItems.length})</h2>
                  <button
                    onClick={toggleUnratedList}
                    className="text-red-500 text-[20px] font-[700] hover:text-red-700 focus:outline-none"
                  >
                    X
                  </button>
                </div>
                <div>
                  <ul>
                    {unratedItems
                      .slice(
                        (unratedCurrentPage - 1) * itemsPerPage,
                        unratedCurrentPage * itemsPerPage
                      )
                      ?.map((item: any, index: any) => (
                        <li key={index}>
                          {item.title} (Page {Math.ceil((data?.indexOf(item) + 1) / itemsPerPage)})
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="flex justify-center mt-4">
                  <button
                    className="mr-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                    onClick={() => handleUnratedPageChange('prev')}
                    disabled={unratedCurrentPage === 1}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <input
                    type="number"
                    value={unratedInputPage}
                    onChange={(e) => setUnratedInputPage(e.target.value)}
                    onBlur={() => handleUnratedPageChange('input')}
                    className="mx-2 px-2 py-1 border rounded-md w-16"
                    min="1"
                    max={Math.ceil(unratedItems.length / itemsPerPage)}
                  />
                  <button
                    className="px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                    onClick={() => handleUnratedPageChange('input')}
                  >
                    Go
                  </button>
                  <button
                    className="ml-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                    onClick={() => handleUnratedPageChange('next')}
                    disabled={unratedCurrentPage === Math.ceil(unratedItems.length / itemsPerPage)}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-center items-center flex-col mt-[10px]">
            {/* Button to trigger calculation */}
            <button
              onClick={calculateMetrics}
              className="bg-[black] text-[white] rounded-[7px] py-[5px] px-[5px]"
            >
              Calculate MOS and Bootstrap Confidence Interval
            </button>

            {/* Display MOS and confidence interval */}
            {mos !== null && confidenceInterval !== null && (
              <div>
                {/* Display Mean Opinion Score (MOS) */}
                <p>
                  <strong>Mean Opinion Score (MOS):</strong> {mos.toFixed(2)}
                  {/* Explanation of MOS */}
                  <span className="text-gray-500"></span>
                </p>
                {/* Display Bootstrap Confidence Interval */}
                <p>
                  <strong>Bootstrap Confidence Interval:</strong> [
                  {confidenceInterval[0].toFixed(2)}, {confidenceInterval[1].toFixed(2)}]
                  {/* Explanation of Confidence Interval */}
                  <span className="text-gray-500"></span>
                </p>
              </div>
            )}
          </div>
          {data?.length > 0 && (
            <>
              {data?.map((obj: any) => (
                <div key={obj?.id} className="mb-4">
                  <h3
                    className="text-lg font-bold cursor-pointer w-fit"
                    onClick={() => handleClick(obj)}
                  >
                    sId: {obj?.id}
                  </h3>
                  <table className="w-full border-collapse border border-gray-300">
                    <thead>
                      <tr>
                        <th className="border p-2">Score</th>
                        <th className="border p-2">Recommended</th>
                        <th className="border p-2">Rating</th>
                        <th className="border p-2">Ref</th>
                      </tr>
                    </thead>
                    <tbody>
                      {obj?.recommVkTourDests?.length > 0 &&
                        obj?.recommVkTourDests?.map((item: any) => (
                          <tr key={item.vktdId}>
                            <td className="border p-2 text-center">{item?.score?.toFixed(2)}</td>
                            <td className="border p-2 flex justify-center grid grid-cols-2 gap-4">
                              <div>
                                <img
                                  src={item?.details?.images[0]?.url}
                                  alt="Recommended"
                                  className="max-w-[300px] w-full"
                                />
                              </div>
                              <div>
                                <p className="truncate">{item?.details?.overview}</p>
                                <div
                                  className="mt-2 px-4 py-2 text-[black]"
                                  onClick={() => handleOpenDetailsModal(item?.details?.overview)}
                                >
                                  View Details
                                </div>
                              </div>
                            </td>
                            <td className="border p-2 text-center">
                              <input
                                type="number"
                                min="1"
                                max="5"
                                value={item.rating || 0}
                                onChange={(e) =>
                                  handleRatingChange(item.vktdId, parseInt(e.target.value))
                                }
                              />
                            </td>
                            <td className="border p-2" onClick={() => handleClick(obj)}>
                              <div className="border px-[5px] py-[5px] text-center">Ref</div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Test2;
