import React, { useEffect, useState } from 'react';

const DataAnalysis = ({ data }: any) => {
  const [confidenceInterval, setConfidenceInterval] = useState<[number, number] | null>(null); // Confidence Interval

  // Function to calculate MOS and confidence interval
  const calculateMetrics = () => {
    // Check if the data array is empty
    if (data?.length <= 0) {
      alert('Upload a file!');
      return;
    }

    // Check if any item has no time value
    if (
      data.some(
        (item: any) => item?.time === undefined || item?.time === null || item?.time?.length <= 0
      )
    ) {
      alert(
        'Some items have no time. Please ensure all items have a time value before calculating metrics.'
      );
      return;
    }

    // Extract times from the list of items
    const times = data.map((item: any) => parseFloat(item.time));

    // Bootstrap Confidence Interval
    const nBootstrapSamples = 1000; // Number of bootstrap samples
    const bootstrapMeans = [];

    // Perform bootstrapping
    for (let i = 0; i < nBootstrapSamples; i++) {
      const bootstrapSample = [];
      for (let j = 0; j < times.length; j++) {
        const randomIndex = Math.floor(Math.random() * times.length);
        bootstrapSample.push(times[randomIndex]);
      }
      const bootstrapMean =
        bootstrapSample.reduce((acc, time) => acc + time, 0) / bootstrapSample.length;
      bootstrapMeans.push(bootstrapMean);
    }

    // Calculate confidence interval
    const alpha = 0.95; // Confidence level
    const lowerQuantile = (1 - alpha) / 2;
    const upperQuantile = 1 - lowerQuantile;
    const sortedMeans = bootstrapMeans.sort((a, b) => a - b);
    const lowerIndex = Math.floor(lowerQuantile * nBootstrapSamples);
    const upperIndex = Math.floor(upperQuantile * nBootstrapSamples);
    const confidenceInterval: [number, number] = [sortedMeans[lowerIndex], sortedMeans[upperIndex]];

    setConfidenceInterval(confidenceInterval);
  };

  return (
    <>
      <div className="flex justify-center items-center flex-col mt-[10px]">
        {/* Button to trigger calculation */}
        <button
          onClick={calculateMetrics}
          className="bg-[black] text-[white] rounded-[7px] py-[5px] px-[5px]"
        >
          Calculate Bootstrap Confidence Interval
        </button>
        {/* Display confidence interval */}
        {confidenceInterval !== null && (
          <div>
            {/* Display Bootstrap Confidence Interval */}
            <p>
              <strong>Bootstrap Confidence Interval:</strong> [{confidenceInterval[0].toFixed(2)},{' '}
              {confidenceInterval[1].toFixed(2)}]{/* Explanation of Confidence Interval */}
              <span className="text-gray-500"></span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const Test5 = () => {
  const [leftLogContent, setLeftLogContent] = useState([]);
  const [rightLogContent, setRightLogContent] = useState([]);

  const handleLeftFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const lines = e.target.result.split('\n');
        setLeftLogContent(lines);
        event.target.value = '';
      };
      reader.readAsText(file);
    }
  };

  const handleRightFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const lines = e.target.result.split('\n');
        setRightLogContent(lines);
        event.target.value = '';
      };
      reader.readAsText(file);
    }
  };

  const modifiedContent = (content: any) => {
    try {
      if (content?.length > 0) {
        const parsedData = (data: any) => {
          try {
            return JSON.parse(data);
          } catch (e) {
            return null;
          }
        };
        let tempArr = [] as any;
        content.map((obj: any, index: any) => {
          if (obj) {
            let parsed = parsedData(obj);
            if (parsed) {
              const speedRegex = /took\s+(\d+(\.\d+)?)\s+milliseconds/;
              const match = parsed?.message?.match(speedRegex);
              if (match) {
                const speed = parseFloat(match[1]);
                parsed['time'] = speed;
              } else {
                console.log('Speed not found in the log line.');
              }
              tempArr.push({ ...parsed });
            }
          }
        });
        return tempArr;
      } else {
        return [];
      }
    } catch (e: any) {
      return null;
    }
  };

  const renderTable = (content: any) => {
    return (
      <table className="w-full">
        <thead>
          <tr>
            {/* <th>Level</th> */}
            {/* <th>Message</th> */}
            {/* <th>Timestamp</th> */}
            <th>Time (milliseconds)</th>
          </tr>
        </thead>
        <tbody>
          {content?.length > 0 &&
            content?.map((line: any, index: any) => {
              // Parse JSON content

              return (
                <tr key={index}>
                  {/* <td>{line?.level}</td> */}
                  {/* <td>{line?.message}</td> */}
                  {/* <td>{line?.timestamp}</td> */}
                  <td className="text-center">{line?.time}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="flex justify-between p-4">
      <div className="flex-1 mr-4">
        <h2 className="text-lg font-semibold mb-2 text-center">w/ node cache</h2>
        <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3V5H6a2 2 0 0 0-2 2v1H2V7a2 2 0 0 1 2-2zm2 10v3h6v-3h3a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h3zm1-9h6v3H7V6zm7 5v1H6V8h8zm-4 3h2v2h-2v-2z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="mt-2">Select File</span>
          <input
            type="file"
            accept=".log, .txt"
            className="hidden"
            onChange={handleLeftFileUpload}
          />
        </label>
        <>
          <DataAnalysis data={modifiedContent(leftLogContent)} />
        </>
        <div className="bg-gray-100 p-4 mt-[5px] border rounded max-h-72 overflow-auto">
          {leftLogContent.length > 0
            ? renderTable(modifiedContent(leftLogContent) ?? null)
            : 'Upload a log file to view its contents here.'}
        </div>
      </div>
      <div className="flex-1">
        <h2 className="text-lg font-semibold mb-2 text-center">w/o node cache</h2>
        <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M4 5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3V5H6a2 2 0 0 0-2 2v1H2V7a2 2 0 0 1 2-2zm2 10v3h6v-3h3a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h3zm1-9h6v3H7V6zm7 5v1H6V8h8zm-4 3h2v2h-2v-2z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="mt-2">Select File</span>
          <input
            type="file"
            accept=".log, .txt"
            className="hidden"
            onChange={handleRightFileUpload}
          />
        </label>
        <>
          <DataAnalysis data={modifiedContent(rightLogContent)} />
        </>
        <div className="bg-gray-100 p-4 mt-[5px] border rounded max-h-72 overflow-auto">
          {rightLogContent.length > 0
            ? renderTable(modifiedContent(rightLogContent) ?? null)
            : 'Upload a log file to view its contents here.'}
        </div>
      </div>
    </div>
  );
};

export default Test5;
