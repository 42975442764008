import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api } from '../../../../../../plugins/axios';

import { v4 as uuidv4 } from 'uuid';

import PaginatedGraph from './PaginatedGraph';

const ITEMS_PER_PAGE = 10;

const ProductPriceReviewTracking: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueries, setSearchQueries] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dataCache, setDataCache] = useState<any>({}); // Cache for fetched data by page
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [sortField, setSortField] = useState('priceChange'); // Default sort field
  const [sortOrder, setSortOrder] = useState('ASC'); // Default sort order
  const [totalCount, setTotalCount] = useState(0); // Store total count of products

  const [currentCondition, setCurrentCondition] = useState<any>({});

  const [currentData, setCurrentData] = useState<any>(null);

  const [searchTerm, setSearchTerm] = useState<any>(null);

  const [searchUUID, setSearchUUID] = useState<any>(null);

  useEffect(() => {
    const fetchSearchQueries = async () => {
      try {
        const response = await api.get('/admin/amazon/webcrape/product/unique-search-queries');
        setSearchQueries(response.data);
      } catch (error) {
        console.error('Error fetching search queries:', error);
      }
    };

    fetchSearchQueries();
  }, []);

  useEffect(() => {
    if (searchUUID) {
      fetchData();
    }
  }, [searchUUID]);

  const handleSearch = async () => {
    if (!currentCondition?.searchQuery?.trim()) {
      alert('Choose search query');
    } else {
      setCurrentCondition({ searchQuery, searchTerm, startDate, endDate, sortField, sortOrder });

      setDataCache({});
      setCurrentPage(1);

      setSearchUUID(uuidv4());
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (dataCache[currentPage]?.data) {
        setCurrentData(dataCache[currentPage]?.data);
      } else {
        const response = await api.get(
          `/admin/amazon/webcrape/product/track/price-review/changes?page=1&limit=${ITEMS_PER_PAGE}&searchQuery=${encodeURIComponent(
            searchQuery
          )}&startDate=${startDate || ''}&endDate=${
            endDate || ''
          }&sortField=${sortField}&sortOrder=${sortOrder}`
        );

        // Update data and cache it for the first page
        setDataCache({
          1: {
            data: response.data.data,
            count: response.data.count,
          },
        });
        setTotalCount(response.data.count); // Set total count from response
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }
    setLoading(false);
  };

  const handlePageChange = async (pageNumber: number) => {
    if (dataCache[pageNumber]) {
      // If we have cached data for the page, just set it
      setCurrentPage(pageNumber);

      // If no cached data, fetch it
      setLoading(true);
      try {
        const response = await api.get(
          `/admin/amazon/webcrape/product/track/price-review/changes?page=${pageNumber}&limit=${ITEMS_PER_PAGE}&searchQuery=${encodeURIComponent(
            currentCondition?.searchQuery
          )}&startDate=${currentCondition?.startDate || ''}&endDate=${
            currentCondition?.endDate || ''
          }&sortField=${currentCondition?.sortField}&sortOrder=${currentCondition?.sortOrder}`
        );

        // Cache the fetched data
        setDataCache((prevCache: any) => ({
          ...prevCache,
          [pageNumber]: {
            data: response.data.data,
            count: response.data.count,
          },
        }));

        // Update current page and total count
        setCurrentPage(pageNumber);
        // setTotalCount(response.data.count);
      } catch (error) {
        console.error('Error fetching data', error);
      }
      setLoading(false);

      return;
    }
  };

  // Calculate total number of pages based on total count
  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

  useEffect(() => {
    if (dataCache[currentPage]?.data) {
      setCurrentData(dataCache[currentPage]?.data);
    }
  }, [currentPage, dataCache, loading]);

  ////////////////////////////////////////////////////////////

  const [detailedData, setDetailedData] = useState(null); // State for detailed info
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const [currentDetailAsin, setCurrentDetailAsin] = useState<any>(null);

  const fetchDetails = async (asin: any) => {
    setLoadingDetails(true);
    try {
      // const response = await fetch(`/api/details?asin=${asin}`); // Adjust the API endpoint accordingly
      // const data = await response.json();
      // setDetailedData(data);
      setCurrentDetailAsin(asin);
      setIsModalOpen(true); // Open the modal after fetching data
    } catch (error) {
      console.error('Error fetching details:', error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const closeModal = () => {
    setDetailedData(null); // Clear detailed data
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-semibold text-gray-700 mb-6">상품 가격, 리뷰 트래킹 (크롤링)</h1>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
            {/* Display your graph here */}
            {!detailedData && (
              <>
                <PaginatedGraph
                  asin={currentDetailAsin}
                  searchQuery={currentCondition?.searchQuery}
                  startDate={currentCondition?.startDate}
                  endDate={currentCondition?.endDate}
                />
              </>

              // <Line data={{
              //   labels: detailedData.priceLabels,
              //   datasets: [{
              //     label: 'Price Changes',
              //     data: detailedData.priceData,
              //     fill: false,
              //     borderColor: 'rgb(75, 192, 192)',
              //     tension: 0.1
              //   }, {
              //     label: 'Review Changes',
              //     data: detailedData.reviewData,
              //     fill: false,
              //     borderColor: 'rgb(255, 99, 132)',
              //     tension: 0.1
              //   }]
              // }} />
            )}
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Filter Section */}
      <div className="flex flex-wrap -mx-2 mb-4">
        {/* Search Query Selector */}
        <div className="w-full md:w-1/4 px-2 mb-4">
          <label className="block text-gray-600 mb-2">Search Query:</label>
          <select
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          >
            <option value="">Select a search query</option>
            {searchQueries.map((query) => (
              <option key={query} value={query}>
                {query}
              </option>
            ))}
          </select>
        </div>

        {/* Date Range Pickers */}
        <div className="w-full md:w-1/4 px-2 mb-4">
          <label className="block text-gray-600 mb-2">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          />
        </div>
        <div className="w-full md:w-1/4 px-2 mb-4">
          <label className="block text-gray-600 mb-2">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          />
        </div>

        {/* Sort Options */}
        <div className="w-full md:w-1/4 px-2 mb-4">
          <label className="block text-gray-600 mb-2">Sort By:</label>
          <select
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          >
            <option value="priceChange">Price Change (%)</option>
            <option value="reviewChange">Review Change (%)</option>
            {/* Add more sorting options as needed */}
          </select>
        </div>
        <div className="w-full md:w-1/4 px-2 mb-4">
          <label className="block text-gray-600 mb-2">Sort Order:</label>
          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          >
            <option value="ASC">Ascending</option>
            <option value="DESC">Descending</option>
          </select>
        </div>
      </div>

      {/* Fetch Data Button */}
      <button
        onClick={() => handleSearch()}
        disabled={loading}
        className={`w-full py-2 mt-4 font-semibold rounded-lg text-white ${
          loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
        }`}
      >
        {loading ? 'Loading...' : 'Fetch Data'}
      </button>

      {/* Display Results with Pagination */}
      {currentData && (
        <div className="mt-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Results</h2>
          <p className="text-gray-600 mb-4">Total Products Found: {totalCount}</p>
          <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b bg-gray-50 text-left text-gray-600">ASIN</th>
                <th className="px-4 py-2 border-b bg-gray-50 text-left text-gray-600">Title</th>
                <th className="px-4 py-2 border-b bg-gray-50 text-left text-gray-600">
                  Price Change (%)
                </th>
                <th className="px-4 py-2 border-b bg-gray-50 text-left text-gray-600">
                  Review Change (%)
                </th>
                <th className="px-4 py-2 border-b bg-gray-50 text-left text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData?.map((item: any) => (
                <>
                  <tr key={item.productAsin}>
                    <td className="px-4 py-2 border-b text-gray-700">{item.productAsin}</td>
                    <td className="px-4 py-2 border-b text-gray-700">{item.productTitle}</td>
                    <td className="px-4 py-2 border-b text-gray-700">{item?.priceChange}%</td>
                    <td className="px-4 py-2 border-b text-gray-700">{item?.reviewChange}%</td>
                    <td className="px-4 py-2 border-b text-gray-700">
                      <button
                        onClick={() => {
                          fetchDetails(item.productAsin);
                        }}
                        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                        disabled={loadingDetails}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="mt-4 flex items-center justify-between">
            <button
              onClick={() => handlePageChange(1)} // Go to the first page
              disabled={currentPage === 1 || loading}
              className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
            >
              First
            </button>

            <button
              onClick={() => handlePageChange(currentPage - 1)} // Go to the previous page
              disabled={currentPage === 1 || loading}
              className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
            >
              Previous
            </button>

            <span className="self-center text-gray-700">
              Page {currentPage} of {totalPages}
            </span>

            <button
              onClick={() => handlePageChange(currentPage + 1)} // Go to the next page
              disabled={currentPage === totalPages || loading}
              className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
            >
              Next
            </button>

            <button
              onClick={() => handlePageChange(totalPages)} // Go to the last page
              disabled={currentPage === totalPages || loading}
              className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
            >
              Last
            </button>

            {/* Input for navigating to a specific page */}
            <div className="flex items-center">
              <input
                type="number"
                min="1"
                max={totalPages}
                value={currentPage}
                onChange={(e) => {
                  const pageNum = Math.max(1, Math.min(totalPages, Number(e.target.value)));
                  handlePageChange(pageNum);
                }}
                className="w-16 px-2 py-1 border border-gray-300 rounded-lg text-center"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPriceReviewTracking;
