import { stringify } from 'qs';
import { api } from '../../../plugins/axios';
import imageCompression from 'browser-image-compression';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

function compressImage(file: File) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    return imageCompression(file, options);
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const uploadImage = async (postId: any, file: File) => {
  try {
    const formFile = new FormData();
    formFile.append('file', file);
    let imageId = uuidv4();
    const fileExt = file.name.split('.').pop();
    const path = `community/posts/${postId}/images/${imageId}.${fileExt}`;
    let result = await api.post('/photo/v2' + `?path=${path}`, formFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: { url: result?.data?.url } };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const uploadImages = async (postId: any, imageArr: any[]) => {
  try {
    const results = await Promise.all(
      map(imageArr, async (image) => {
        const form = new FormData();
        const compressBlob = await compressImage(image.file);
        if (!compressBlob) {
          return;
        }
        const compressfile = new File([compressBlob], image.file.name, {
          type: image.file.type,
        });
        // form.append('file', compressfile);
        return uploadImage(postId, compressfile);
      })
    );
    return results;
  } catch (e) {
    return null;
  }
};

export const uploadImagesWoCompress = async (postId: any, imageArr: any[]) => {
  try {
    const results = await Promise.all(
      map(imageArr, async (image) => {
        // const form = new FormData();
        // const compressBlob = await compressImage(image.file);
        // if (!compressBlob) {
        //   return;
        // }
        // const compressfile = new File([compressBlob], image.file.name, {
        //   type: image.file.type,
        // });
        // form.append('file', compressfile);
        return uploadImage(postId, image.file);
      })
    );
    return results;
  } catch (e) {
    return null;
  }
};

export const createCommunityPost = async (createCommunityPostParams: any) => {
  try {
    let result = await api.post('/community/posts', createCommunityPostParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const editCommunityPost = async (editCommunityPostParams: any) => {
  try {
    let result = await api.patch(
      `/community/posts/${editCommunityPostParams?.uniqueId}`,
      editCommunityPostParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const deleteCommunityPost = async (deleteCommunityPostParams: any) => {
  try {
    let result = await api.delete(`/community/posts/${deleteCommunityPostParams?.uniqueId}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const getCommunityPost = async (postId: any) => {
  try {
    let result = await api.get(`/community/posts/${postId}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const createCommunityPostComment = async (
  postId: any,
  createCommunityPostCommentParams: any
) => {
  try {
    let result = await api.post(
      `/community/posts/${postId}/comments`,
      createCommunityPostCommentParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const editCommunityPostComment = async (editCommunityPostCommentParams: any) => {
  try {
    let result = await api.patch(
      `/community/posts/${editCommunityPostCommentParams?.postId}/comments/${editCommunityPostCommentParams?.id}`,
      editCommunityPostCommentParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const deleteCommunityPostComment = async (deleteCommunityPostCommentParams: any) => {
  try {
    let result = await api.delete(
      `/community/posts/${deleteCommunityPostCommentParams?.postId}/comments/${deleteCommunityPostCommentParams?.id}`
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const createCommunityPostCommentReply = async (
  postId: any,
  parentCommentId: any,
  createCommunityPostCommentReplyParams: any
) => {
  try {
    let result = await api.post(
      `/community/posts/${postId}/comments/${parentCommentId}/replies`,
      createCommunityPostCommentReplyParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const getCommunityBanners = async (queryString: string) => {
  try {
    let result = await api.get('/community/banners' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};
