import { useEffect, useRef, useState } from 'react';
import ImageUploading from 'react-images-uploading';

import { v4 as uuidv4 } from 'uuid';

import { Button } from '../../../../components/Button';

import { authUserTokenObjState } from '../../../../ridge/ridge';

import { ReactComponent as IconTrashImg } from '../../../../assets/icons/icon-trash.svg';
import { ReactComponent as IconUpdateImg } from '../../../../assets/icons/icon-update.svg';
import { ReactComponent as IconImageUploadImg } from '../../../../assets/icons/icon-image-upload.svg';
import { Icon } from '../../../../components/Icon';

import {
  createCommunityPost,
  uploadImages,
  uploadImagesWoCompress,
} from '../../../../api_v2/non_admin/community';
import { toast } from 'react-toastify';
import FroalaEditor from './components/FroalaEditor';

const categoryNameObj: { [key: string]: any } = {
  domesticTravel: '국내여행',
  internationalTravel: '해외여행',
  domesticFestival: '국내축제',
  internationalFestival: '해외축제',
  etc: '기타',
};

export default function PostAdd(props: any) {
  const authUserTokenObj = authUserTokenObjState.useValue();
  const [open, setOpen] = useState(true);
  const [images, setImages] = useState<any>([]);
  const maxNumber = 5;
  const [categories, setCategories] = useState<any>({
    domesticTravel: false,
    internationalTravel: false,
    domesticFestival: false,
    internationalFestival: false,
    etc: false,
  });
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [content, setContent] = useState<any>('');
  const [addPostStatus, setAddPostStatus] = useState<any>(false);

  const handleAddPost = async () => {
    try {
      setAddPostStatus(true);
      let postId = uuidv4();
      if (selectedCategories?.length >= 1) {
        let uploadImagesRes;
        if (images?.length >= 1) {
          uploadImagesRes = await uploadImages(postId, images);
          // uploadImagesRes = await uploadImagesWoCompress(postId, images);
        }
        if (uploadImagesRes && uploadImagesRes?.length >= 1) {
          uploadImagesRes = uploadImagesRes.map((uploadImage) => {
            return { url: uploadImage?.data?.url };
          });
        } else {
        }

        let createCommunityPostRes = await createCommunityPost({
          uniqueId: postId,
          images: uploadImagesRes,
          categories: selectedCategories,
          content: editorDetail.trim(), //content?.trim(),
        });

        if (createCommunityPostRes?.success == true) {
          if (props?.from == 'MyPosts') {
            props.refetchMyPagePosts();
          } else if (props?.from == 'Posts') {
            props.refetchPosts();
          }
          toast.success('성공적으로 등록되었습니다.');
          props?.closeDrawer();
        } else {
          toast.error('오류가 발생하였습니다.');
        }
      } else {
        window.confirm('카테고리를 선택해주세요');
      }
      setAddPostStatus(false);
    } catch (e) {}
  };

  const onChange = (imageList: any, addUpdateIndex: any) => {
    setImages(imageList);
  };

  const onError = (errors: any, files: any) => {
    if (errors?.maxNumber == true) {
      window.confirm('사진은 최대 5장까지 등록이 가능합니다.');
    }
  };

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;

    setCategories({
      ...categories,
      [name]: checked,
    });
  };

  useEffect(() => {
    const newSelectedCategories = Object.keys(categories).filter(
      (category: any) => categories[category]
    );
    setSelectedCategories(newSelectedCategories);
  }, [categories]);

  const handleContentChange = (e: any) => {
    setContent(e?.target?.value);
  };

  const [editorDetail, setEditorDetail] = useState('');

  return (
    <div>
      <div className="mb-[10px]">
        <div onClick={() => setOpen(!open)} className="flex justify-between pb-4 cursor-pointer">
          <h4>카테고리</h4>
          <Icon.ChevronDown className={`${open ? 'rotate-180' : ''} transition-transform z-[0]`} />
        </div>
        {open && (
          <div className="flex flex-wrap">
            {Object.keys(categories).map((category) => (
              <>
                <label className="mr-[7px] my-[5px]">
                  <input
                    className="mr-[7px]"
                    type="checkbox"
                    name={category}
                    checked={categories[category]}
                    onChange={handleCheckboxChange}
                  />
                  {categoryNameObj[category]}
                </label>
              </>
            ))}
          </div>
        )}
      </div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        onError={onError}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <div className="upload__image-wrapper mb-[10px]">
            <div className="flex items-center justify-between mb-[0px]">
              <button
                className="border-[2px] border-[#dcdcde] rounded-[10px] p-[10px]"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <IconImageUploadImg width={'30px'} />
              </button>
            </div>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div className="flex flex-wrap mb-[5px]">
              {imageList.map((image, index) => (
                <div
                  key={index}
                  className="image-item relative w-[80px] aspect-[1/1] mr-[5px] mb-[5px]"
                >
                  <img
                    src={image['data_url']}
                    alt=""
                    className="w-full h-full rounded-[8px] object-cover"
                  />
                  <div className="image-item__btn-wrapper absolute top-[0px] flex justify-between w-full rounded-t-[8px] bg-[black] opacity-[0.65] p-[2px]">
                    <button onClick={() => onImageUpdate(index)}>
                      <IconUpdateImg width={'20px'} height={'20px'} />
                    </button>
                    <button onClick={() => onImageRemove(index)}>
                      <IconTrashImg fill="white" width={'20px'} height={'20px'} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
      {/* <textarea
        className="w-full mb-[5px] rounded-[10px] resize-none border-[#dcdcde]"
        style={{ height: '30vh', maxHeight: '300px' }}
        placeholder=""
        value={content}
        onChange={(e) => {
          handleContentChange(e);
        }}
      /> */}
      <div className="mb-[10px]">
        <FroalaEditor
          defaultValue={''}
          onChange={(detailModel) => {
            setEditorDetail(detailModel);
          }}
        />
      </div>
      <Button
        disabled={addPostStatus}
        onClick={() => {
          handleAddPost();
        }}
        className="w-full h-[50px] mb-[25px] rounded font-bold text-[#FFFFFF] bg-[#FF6400] flex justify-center items-center"
      >
        {addPostStatus ? '등록중...' : '등록'}
      </Button>
    </div>
  );
}
