import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { api } from '../../../../../../plugins/axios';

import { v4 as uuidv4 } from 'uuid';

// Define the Product interface
interface Product {
  asin: string;
  title: string;
  price: number;
  isKorean: string; // Consider changing to boolean if only 'yes' or 'no' is used
  searchQuery: string;
  currencySymbol: string;
  currency: string;
  categories: string;
  reviews: number; // Assuming this is a number
  date: string; // Assuming date is a string in a certain format
}

export default function ProductList() {
  const [dataCache, setDataCache] = useState<any>({}); // Cache for each page
  const [currentData, setCurrentData] = useState<any>(null);
  const [selectedQuery, setSelectedQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInput, setPageInput] = useState(''); // Input for page number
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5;
  const pageRange = 5; // Number of pages to display in pagination
  const [searchQueries, setSearchQueries] = useState([]); // State to hold unique search queries

  const [currentCondition, setCurrentCondition] = useState<any>({});

  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState<any>(null);

  const [searchUUID, setSearchUUID] = useState<any>(null);

  useEffect(() => {
    const fetchSearchQueries = async () => {
      try {
        const response = await api.get('/admin/amazon/webcrape/product/unique-search-queries');
        setSearchQueries(response.data);
      } catch (error) {
        console.error('Error fetching search queries:', error);
      }
    };

    fetchSearchQueries();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    if (dataCache[currentPage]) {
      setCurrentData(dataCache[currentPage]);
    } else {
      try {
        const response = await api.get(
          `/admin/amazon/webcrape/product/list?searchQuery=${
            currentCondition?.selectedQuery ?? ''
          }&searchTerm=${
            currentCondition?.searchTerm ?? ''
          }&page=${currentPage}&limit=${itemsPerPage}`
        );
        const newData = response.data.data;
        const totalItems = response.data.total;

        setDataCache((prevCache: any) => ({
          ...prevCache,
          [currentPage]: newData,
        }));

        setCurrentData(newData);
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchUUID) {
      fetchData();
    }
  }, [searchUUID]);

  const handleSelectChange = (event: any) => {
    setSelectedQuery(event.target.value);
  };

  const handleSearch = async () => {
    setCurrentCondition({ selectedQuery, searchTerm });

    setDataCache({});
    setCurrentPage(1);

    setSearchUUID(uuidv4());
  };

  const handlePageChange = async (page: any) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);

      setSearchUUID(uuidv4());
    }
  };

  const getPageNumbers = () => {
    const start = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const end = Math.min(totalPages, start + pageRange - 1);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handlePageInputChange = (event: any) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setPageInput(value);
    }
  };

  const goToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageChange(pageNumber);
    }
    setPageInput('');
  };

  const highlightText = (text: any, searchTerm: any) => {
    if (!searchTerm) return text; // Return the text as is if searchTerm is empty

    const regex = new RegExp(`(${searchTerm})`, 'gi'); // Case-insensitive match
    return text.replace(regex, '<span class="bg-yellow-300">$1</span>'); // Wrap match with span for highlighting
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold text-gray-700 mb-6">상품 리스트 (크롤링)</h1>
      {searchQueries.length > 0 ? (
        <>
          <div className="w-full space-y-4">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by Title or ASIN..."
              className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              className="w-full p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedQuery}
              onChange={handleSelectChange}
            >
              <option value="">All Queries</option>
              {searchQueries.map((query) => (
                <option key={query} value={query}>
                  {query}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={() => handleSearch()}
            disabled={loading}
            className="w-full my-4 py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Search
          </button>

          {currentData && (
            <>
              <table className="min-w-full border border-gray-300">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="p-2 border">ASIN</th>
                    <th className="p-2 border">Title</th>
                    <th className="p-2 border">Price</th>
                    <th className="p-2 border">Reviews</th>
                    <th className="p-2 border">Is Korean</th>
                    <th className="p-2 border">Categories</th>
                    <th className="p-2 border">Search Query</th>
                    <th className="p-2 border">Currency</th>
                    <th className="p-2 border">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item: any) => (
                    <tr key={item.asin} className="hover:bg-gray-50">
                      <td
                        className="p-2 border"
                        dangerouslySetInnerHTML={{
                          __html: highlightText(item.asin, currentCondition?.searchTerm ?? ''),
                        }}
                      ></td>
                      <td
                        className="p-2 border"
                        dangerouslySetInnerHTML={{
                          __html: highlightText(item.title, currentCondition?.searchTerm ?? ''),
                        }}
                      ></td>
                      <td className="p-2 border">
                        {item.currencySymbol}
                        {item.price}
                      </td>
                      <td className="p-2 border">{item.reviews}</td>
                      <td className="p-2 border">{item.isKorean}</td>
                      <td className="p-2 border">{item.categories}</td>
                      <td className="p-2 border">{item.searchQuery}</td>
                      <td className="p-2 border">{item.currency}</td>
                      <td className="p-2 border">{item.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-center items-center my-4">
                <button
                  className={`mx-1 px-3 py-1 border rounded ${
                    currentPage === 1
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                >
                  First
                </button>
                <button
                  className={`mx-1 px-3 py-1 border rounded ${
                    currentPage === 1
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {getPageNumbers().map((pageNum) => (
                  <button
                    key={pageNum}
                    className={`mx-1 px-3 py-1 border rounded ${
                      currentPage === pageNum
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 hover:bg-gray-300'
                    }`}
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                ))}
                <button
                  className={`mx-1 px-3 py-1 border rounded ${
                    currentPage === totalPages
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
                <button
                  className={`mx-1 px-3 py-1 border rounded ${
                    currentPage === totalPages
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  Last
                </button>

                <input
                  type="text"
                  value={pageInput}
                  onChange={handlePageInputChange}
                  placeholder="Go to page"
                  className="mx-2 p-2 border border-gray-300 rounded"
                />
                <button
                  className="mx-1 px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                  onClick={goToPage}
                >
                  Go
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
}
