import React, { useState, useEffect } from 'react';
import Test1 from './Test1';
import Test2 from './Test2';
import Test3 from './Test3';
import Test4 from './Test4';
import Test5 from './Test5';
import Test6 from './Test6';
import Test7 from './Test7';
import { api } from '../../../../../../plugins/axios';

const Tests = () => {
  const [vkTourDests, setVkTourDests] = useState<any>(null);

  useEffect(() => {
    api.get('/admin/ai/tests/get-vk-tour-dests').then((res) => {
      setVkTourDests(res?.data?.vkTourDests[0] ?? []);
    });
  }, []);

  const tabs = [
    {
      name: 'recommend_all-MiniLM-L6-v2_bookmark_community_post',
      component: <Test1 vkTourDests={vkTourDests} />,
    },
    {
      name: 'recommend_all-MiniLM-L6-v2_search_history_community_post',
      component: <Test2 vkTourDests={vkTourDests} />,
    },
    {
      name: 'image_search_vit-gpt2-image-captioning_text_based_vk_tour_dests',
      component: <Test3 vkTourDests={vkTourDests} />,
    },
    {
      name: 'image_search_vit-gpt2-image-captioning_image_based_vk_tour_dests',
      component: <Test4 vkTourDests={vkTourDests} />,
    },
    { name: 'ga_data_process_community_post_view_data', component: <Test5 /> },
    {
      name: 'find_info_distilbert-base-uncased-distilled-squad',
      component: <Test6 vkTourDests={vkTourDests} />,
    },
    { name: 'image_load_speed_compression_lazy_loading', component: <Test7 /> },
  ];

  // Determine initial tab based on URL parameter
  const getInitialTab = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabName = urlParams.get('tab');
    const tabIndex = tabs.findIndex((tab) => tab.name === tabName);
    return tabIndex === -1 ? 0 : tabIndex;
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    updateURL(tabs[index].name);
  };

  const updateURL = (tabName: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('tab', tabName);
    window.history.replaceState({}, '', url.toString());
  };

  useEffect(() => {
    updateURL(tabs[activeTab].name);
  }, []);

  return (
    <>
      {vkTourDests?.length > 0 ? (
        <>
          <div className="overflow-x-auto mb-4">
            <div className="flex">
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  className={`mr-4 px-4 py-2 border ${activeTab === index ? 'bg-gray-200' : ''}`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab.name}
                </button>
              ))}
            </div>
          </div>

          {tabs[activeTab].component}
        </>
      ) : (
        <>
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        </>
      )}
    </>
  );
};

export default Tests;
