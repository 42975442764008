import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { api } from '../../../../../../plugins/axios';

interface DataPoint {
  date: string;
  price: number;
  reviews: number;
}

interface PaginatedGraphProps {
  asin: string;
  startDate?: string; // Format: YYYY-MM-DD, optional
  endDate?: string; // Format: YYYY-MM-DD, optional
  searchQuery: string;
}

const PaginatedGraph: React.FC<PaginatedGraphProps> = ({
  asin,
  startDate,
  endDate,
  searchQuery,
}) => {
  const [dataCache, setDataCache] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  const [currentData, setCurrentData] = useState<any>(null);

  const [itemsPerPage, setItemsPerPage] = useState(1000);

  // Fetch data from API when component mounts or props change
  const fetchData = async () => {
    setLoading(true);
    if (dataCache[currentPage]?.data) {
      setCurrentData(dataCache[currentPage]?.data);
      setTotalCount(dataCache[currentPage]?.count ?? 0);
    } else {
      try {
        let firstPart = '/admin/amazon/webcrape/product/track/price-review/changes/detail';
        const response = await api.get(
          `${firstPart}?asin=${asin}&searchQuery=${searchQuery}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${itemsPerPage}`
        );

        setDataCache((prev: any) => ({
          ...prev,
          [currentPage]: { data: response?.data?.data, count: response?.data?.count },
        }));
        setCurrentData(response?.data?.data);
        setTotalCount(response?.data?.count ?? 0);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const totalPages = Math.ceil((totalCount || 0) / itemsPerPage);

  const handlePageChange = async (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentData(null); // Clear current data
    setDataCache({}); // Clear cached data
    const newItemsPerPage = Number(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  return (
    <div>
      {/* Dropdown for Items Per Page */}
      <div className="mb-4 flex items-center space-x-4">
        <label htmlFor="itemsPerPage" className="text-gray-700">
          Items per page:
        </label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="px-2 py-1 border rounded-lg"
        >
          {[10, 50, 100, 500, 1000].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={currentData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="price" stroke="#8884d8" />
          <Line type="monotone" dataKey="reviews" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>

      {/* Pagination Controls */}
      <div className="mt-4 flex flex-col items-center space-y-4">
        <div className="flex justify-between w-full">
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1 || loading}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
          >
            First
          </button>

          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1 || loading}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
          >
            Previous
          </button>

          {/* Page Number Buttons */}
          <div className="flex space-x-2">
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .slice(Math.max(0, currentPage - 3), Math.min(totalPages, currentPage + 2))
              .map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  disabled={page === currentPage || loading}
                  className={`px-3 py-1 rounded-lg ${
                    page === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-300'
                  }`}
                >
                  {page}
                </button>
              ))}
          </div>

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages || loading}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
          >
            Next
          </button>

          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages || loading}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:opacity-50"
          >
            Last
          </button>
        </div>

        {/* Direct Page Navigation */}
        <div className="flex items-center space-x-2">
          <span className="text-gray-700">Go to page:</span>
          <input
            type="number"
            min={1}
            max={totalPages}
            value={currentPage}
            onChange={(e) => {
              const newPage = Number(e.target.value);
              if (newPage > 0 && newPage <= totalPages) {
                setCurrentPage(newPage);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                const newPage = Number(e.currentTarget.value);
                if (newPage > 0 && newPage <= totalPages) {
                  handlePageChange(newPage);
                }
              }
            }}
            className="w-16 px-2 py-1 border rounded-lg"
          />
          <span className="text-gray-700">of {totalPages}</span>
        </div>
      </div>
    </div>
  );
};

export default PaginatedGraph;
