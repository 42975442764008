import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { api } from '../../../../../../plugins/axios';

// Dummy data
const dummyData = [
  {
    id: 1,
    userId: 'user1',
    nickName: 'Nick1',
    likeCount: '10',
    commentCount: '5',
    videoCount: '3',
    convertedLikeCount: 10,
    convertedCommentCount: 5,
    convertedVideoCount: 3,
    searchQuery: 'fun',
    tabType: 'type1',
    tags: 'tag1',
    imgAltText: 'alt1',
    collectionUtcTime: new Date(),
    collectionDate: new Date(),
    postedTime: '10:00 AM',
    postedTimeInfo: 'Info1',
    videoUrl: 'http://example.com/video1',
  },
  {
    id: 2,
    userId: 'user2',
    nickName: 'Nick2',
    likeCount: '20',
    commentCount: '10',
    videoCount: '6',
    convertedLikeCount: 20,
    convertedCommentCount: 10,
    convertedVideoCount: 6,
    searchQuery: 'tech',
    tabType: 'type2',
    tags: 'tag2',
    imgAltText: 'alt2',
    collectionUtcTime: new Date(),
    collectionDate: new Date(),
    postedTime: '2:00 PM',
    postedTimeInfo: 'Info2',
    videoUrl: 'http://example.com/video2',
  },
];

// Extract unique search queries from data
// const uniqueSearchQueries = [...new Set(dummyData.map((item) => item.searchQuery))];

const ExcelDownload = (props: any) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedQuery, setSelectedQuery] = useState('');

  const handleDownload = async () => {
    try {
      if (selectedQuery) {
        if (startDate && endDate) {
          if (new Date(startDate) > new Date(endDate)) {
            alert('Start date cannot be greater than end date.');
            return;
          }
        }
        const response = await api.get('/admin/tiktok/webcrape/post/download/excel', {
          params: {
            startDate: startDate,
            endDate: endDate,
            searchQuery: selectedQuery,
          },
        });

        if (response?.status == 200) {
          if (response?.data?.length > 0) {
            // Modify the response data to convert 'tags' array into a string
            const modifiedData = response?.data.map((item: any) => ({
              ...item,
              tags: item.tags ? item.tags.join(', ') : '', // Join the tags array into a comma-separated string
            }));

            // Create a worksheet from the modified data
            const ws = XLSX.utils.json_to_sheet(modifiedData);

            // Create a new workbook with the data in a sheet
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Adding the worksheet

            // Write to Excel file and trigger download
            let fileName = `${selectedQuery}_`;
            if (startDate && endDate) {
              // If both start and end date are provided
              fileName += `${startDate}_to_${endDate}`;
            } else if (startDate) {
              // If only start date is provided
              fileName += `from_${startDate}`;
            } else if (endDate) {
              // If only end date is provided
              fileName += `until_${endDate}`;
            } else {
              // If no dates are provided, indicate entire period
              fileName += 'entire_period';
            }

            XLSX.writeFile(wb, `${fileName}.xlsx`);
          } else {
            alert('empty: no data for given conditions');
          }
        } else {
          alert('error');
        }
      } else {
        alert('검색어를 선택해주세요');
      }
      console.log('selectedQuery', selectedQuery);
    } catch (e) {
      console.log('e', e);
    }
    // Filter data based on period and search query
    // const filteredData = dummyData.filter((item) => {
    //   const isInDateRange =
    //     (!startDate && !endDate) || // No date range selected
    //     (new Date(item.collectionDate) >= new Date(startDate) &&
    //       new Date(item.collectionDate) <= new Date(endDate));
    //   const matchesQuery = !selectedQuery || item.searchQuery === selectedQuery;
    //   return isInDateRange && matchesQuery;
    // });
    // // Convert to Excel format
    // const worksheet = XLSX.utils.json_to_sheet(filteredData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    // // Trigger download
    // XLSX.writeFile(workbook, 'FilteredData.xlsx');
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mb-6 max-w-4xl mx-auto">
      {/* Title */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Excel Download</h2>

      {/* Form Inputs and Button */}
      <div className="space-y-6 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0">
        {/* Start Date */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* End Date */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Search Query */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">Search Query:</label>
          <select
            value={selectedQuery}
            onChange={(e) => setSelectedQuery(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">-- Select a Query --</option>
            {props?.searchQueries?.map((query: any, index: any) => (
              <option key={index} value={query}>
                {query}
              </option>
            ))}
          </select>
        </div>

        {/* Download Button */}
        <div className="flex justify-center sm:col-span-2">
          <button
            onClick={handleDownload}
            // disabled={!startDate || !endDate}
            className={`px-6 py-3 w-full sm:w-auto font-medium text-white rounded-md shadow-md ${
              //   !startDate || !endDate
              //     ? 'bg-gray-400 cursor-not-allowed'
              //     :
              'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500'
            }`}
          >
            Download Excel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExcelDownload;
