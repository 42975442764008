import React, { useEffect, useState } from 'react';
import { api } from '../../../../../../plugins/axios';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboard,
  faFileExcel,
  faTrash,
  faUpload,
  faArrowLeft,
  faArrowRight,
  faEye,
  faSave,
  faDownload,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import { pipeline, env, cat } from '@xenova/transformers';
import { env as envOnnx } from 'onnxruntime-web';
import { toast } from 'react-toastify';

import * as XLSX from 'xlsx';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

const LeftComp = ({ vkTourDests }: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(5);
  const [pageInput, setPageInput] = useState('1');

  const searchListByProperty = () => {
    const results = vkTourDests.filter(
      (obj: any) => obj.overview.includes(searchTerm) || obj.title.includes(searchTerm)
    );
    setSearchResults(results);
    setCurrentPage(1); // Reset to first page on new search
  };

  const [clickedItem, setClickedItem] = useState<any>(null);

  const handleSelectItem = (item: any) => {
    setClickedItem(item);
    setSelectedItem(item);
    setAnswer('');
  };

  const resetSelectedItem = () => {
    setSelectedItem(null);
    setAnswer('');
  };

  const resetSearchResults = () => {
    setSearchResults([]);
  };

  const getRandomObject = () => {
    const randomIndex = Math.floor(Math.random() * vkTourDests.length);

    setClickedItem(vkTourDests[randomIndex]);
    setSelectedItem(vkTourDests[randomIndex]);
    setSearchResults([]);
    setAnswer('');
  };

  const [findingAnswerError, setFindingAnswerError] = useState<any>(null);
  const [findingAnswerStatus, setFindingAnswerStatus] = useState<any>(null);

  const findAnswer = async () => {
    try {
      setFindingAnswerError(null);
      setFindingAnswerStatus(true);
      if (question?.trim()?.length > 0) {
        let translateRes = await api.post('/admin/ai/util/translate', {
          dataToTranslate: question?.trim(),
          toLanguage: 'en',
        });
        if (translateRes?.data?.translatedText) {
          let bertQARes = await bertQA(
            translateRes?.data?.translatedText,
            selectedItem?.translatedOverview
          );
          if (bertQARes && (bertQARes as any)?.answer) {
            let translateRes2 = await api.post('/admin/ai/util/translate', {
              dataToTranslate: (bertQARes as any)?.answer?.trim(),
              toLanguage: 'ko',
            });
            if (translateRes2?.data?.translatedText) {
              setAnswer(translateRes2?.data?.translatedText);
            }
          } else {
            setFindingAnswerError('bertQA: an error occured');
            throw Error('bertQA: an error occured');
          }
        }
      } else {
        toast.dark('검색내용을 입력해주세요!');
      }
      setFindingAnswerStatus(false);
    } catch (e) {
      setFindingAnswerError('bertQA: an error occured');
      setFindingAnswerStatus(false);
    }
  };

  // Pagination logic
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = searchResults.slice(indexOfFirstResult, indexOfLastResult);
  const totalPages = Math.ceil(searchResults.length / resultsPerPage);

  const paginate = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }
    setCurrentPage(pageNumber);
    setPageInput(pageNumber.toString());
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isNaN(Number(value)) && value !== '') {
      setPageInput(value);
    }
  };

  const handlePageInputSubmit = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber)) {
      paginate(pageNumber);
    }
  };

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  const continuePage = () => {
    try {
      // problem
      window.location.href =
        '/admin/ai/tests?tab=find_info_distilbert-base-uncased-distilled-squad' +
        '&continueProcess=y&vkTourDestId=' +
        selectedItem?.id;
    } catch (e) {}
  };

  const restartPage = () => {
    try {
      window.location.href =
        '/admin/ai/tests?tab=find_info_distilbert-base-uncased-distilled-squad';
    } catch (e) {}
  };

  useEffect(() => {
    if (clickedItem) {
      setSelectedItem(clickedItem);
    } else {
      if (vkTourDests?.length > 0 && parsed?.vkTourDestId && parsed?.continueProcess == 'y') {
        let found = vkTourDests.find((obj: any) => obj?.id == parsed?.vkTourDestId);
        if (found) {
          setSelectedItem(found);
        }
      }
    }

    // }, [parsed, vkTourDests]);
  }, [parsed, vkTourDests, clickedItem]);

  const ratingDescriptions = [
    {
      rating: 1,
      description:
        'Poor: The answer does not address the question or context provided. It fails to find relevant information and may be misleading.',
    },
    {
      rating: 2,
      description:
        'Fair: The answer partially addresses the question but contains inaccuracies or lacks clarity in finding information within the provided context.',
    },
    {
      rating: 3,
      description:
        'Average: The answer is somewhat accurate in finding information within the provided context but may lack depth or fail to fully address the question.',
    },
    {
      rating: 4,
      description:
        'Good: The answer is accurate in finding relevant information within the provided context. It demonstrates a clear understanding of the question and context.',
    },
    {
      rating: 5,
      description:
        'Excellent: The answer is highly accurate in finding comprehensive and relevant information within the provided context. It fully addresses the question and provides valuable insights.',
    },
  ];

  const bertQA = async (questionData: any, contextData: any, maxRetries = 3) => {
    try {
      console.log('bertQA');

      let retries = 0;
      console.log('try', retries + 1);

      while (retries < maxRetries) {
        try {
          const answerer = await pipeline(
            'question-answering',
            'Xenova/distilbert-base-uncased-distilled-squad'
          );
          const response = await answerer(questionData, contextData);

          if (response) {
            return response;
          } else {
            console.error(`Request failed`);
          }
        } catch (error) {
          console.error('Error during request:', error);
        }

        retries += 1;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      console.log('bertQA: could not work after 3 attemps');

      return { message: 'could not work after 3 attemps' };
    } catch (e) {
      console.log('bertQA e: ', e);
      return e;
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Overview copied to clipboard');
      })
      .catch((err: any) => {
        alert('Failed to copy text: ' + err);
      });
  };

  // State for managing saved items
  const [savedItems, setSavedItems] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);

  // Function to add selected item to saved items list
  const addToSavedItems = () => {
    if (selectedItem) {
      const newItem = {
        id: selectedItem.id,
        title: selectedItem.title,
        overview: selectedItem.overview,
        question: question,
        answer: answer,
        rating: selectedItem?.rating ?? null,
      };

      // Check if the item already exists in savedItems
      const isDuplicate = savedItems.some((item) => item.id === newItem.id);

      if (!isDuplicate) {
        // Item is not a duplicate, add it to savedItems
        setSavedItems([...savedItems, newItem]);
        alert('Added to saved items');
      } else {
        // Item is already in the list, display a message or handle it as needed
        alert('Item is already in the list');
      }
    }
  };

  // Function to remove item from saved items list
  const removeFromSavedItems = (id: string) => {
    const updatedItems = savedItems.filter((item) => item.id !== id);
    setSavedItems(updatedItems);
  };

  // Function to toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const downloadAsExcel = () => {
    if (window.confirm('download excel file?')) {
      const currentTime = new Date().toLocaleString().replace(/:/g, '-'); // Format time
      const fileName = `saved_items_${currentTime}.xlsx`; // Append time to file name

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(savedItems);

      // Add header column names
      const header = ['id', 'title', 'overview', 'question', 'answer', 'rating'];
      XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'SavedItems');

      XLSX.writeFile(workbook, fileName);
    }
  };

  const emptySavedItems = () => {
    if (window.confirm('empty the list?')) {
      setSavedItems([]);
    }
  };

  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [currentTooltip, setCurrentTooltip] = useState(null);

  const handleMouseEnter = (tooltip: any, event: any) => {
    const rect = event.target.getBoundingClientRect();

    setTooltipPosition({ x: rect.left, y: rect.top });
    setCurrentTooltip(tooltip);
  };

  const handleMouseLeave = () => {
    setCurrentTooltip(null);
  };

  const saveListToLocalStorage = () => {
    if (window.confirm('save list to ls')) {
      localStorage.setItem(
        'saved-ls-list-test-distilbert-base-uncased-distilled-squad',
        JSON.stringify(savedItems)
      );
      alert('List saved to local storage!');
    }
  };

  const combineWithLocalStorageList = () => {
    if (window.confirm('combine with ls list')) {
      const savedItemsFromStorage = JSON.parse(
        localStorage.getItem('saved-ls-list-test-distilbert-base-uncased-distilled-squad') || '[]'
      );
      const newItems = savedItemsFromStorage.filter(
        (item: any) => !savedItems.some((savedItem) => savedItem.id === item.id)
      );
      const combinedList = [...savedItems, ...newItems];
      setSavedItems(combinedList);
      alert('Combined local storage list with current list!');
    }
  };

  const emptyLocalStorageList = () => {
    if (window.confirm('empty ls list')) {
      localStorage.removeItem('saved-ls-list-test-distilbert-base-uncased-distilled-squad');
      alert('Removed saved local storage list!');
    }
  };

  const combineWithLocalComputerList = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (window.confirm('combine with local computer list')) {
      const file = event.target.files?.[0];
      if (!file) return;

      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Convert array data to object data with headers
        const headers = jsonData[0] as string[];
        const items = jsonData
          .slice(1)
          .filter((row: any) => row.some((cell: any) => cell !== ''))
          .map((row: any) => {
            const item: any = {};
            headers.forEach((header, index) => {
              item[header] = row[index];
            });
            return item;
          });

        // Combine the new items with the current list
        const combinedList = [
          ...savedItems,
          ...items.filter((item: any) => !savedItems.some((savedItem) => savedItem.id === item.id)),
        ];

        // Update the saved items state with the combined list
        setSavedItems(combinedList);
        alert('Combined local computer list with current list!');
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div className="p-4">
      <div className="flex flex-wrap gap-[7px]">
        {currentTooltip && (
          <div
            className="absolute tooltip border rounded-[5px] p-[30px] bg-[white] z-[100000]"
            style={{ top: tooltipPosition.y, left: tooltipPosition.x - 50 }}
          >
            {currentTooltip}
          </div>
        )}

        <div>
          <p className="text-center">Current</p>
          <div className="flex items-center gap-[10px] my-[15px] border rounded-[10px] px-[7px] py-[7px] w-fit">
            <div
              onMouseEnter={(e: any) => handleMouseEnter('view-current-list', e)}
              onMouseLeave={handleMouseLeave}
              onClick={toggleModal}
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <div className="bg-[black] text-white rounded-full w-6 h-6 flex items-center justify-center">
                  <FontAwesomeIcon icon={faClipboard} />
                </div>
                {savedItems.length > 0 && (
                  <span
                    style={{ width: savedItems.length >= 10 ? '-webkit-fill-available' : '' }}
                    className="absolute top-[-7px] right-[-4px] bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                  >
                    {savedItems.length}
                  </span>
                )}
              </div>
            </div>
            <div
              onMouseEnter={(e: any) => handleMouseEnter('empty-current-list', e)}
              onMouseLeave={handleMouseLeave}
              onClick={emptySavedItems}
              className="bg-gray-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>

            <div
              onMouseEnter={(e: any) => handleMouseEnter('download-current-list', e)}
              onMouseLeave={handleMouseLeave}
              onClick={downloadAsExcel}
              className="bg-[black] text-white rounded-full w-6 h-6 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faFileExcel} />
            </div>
          </div>
        </div>
        <div>
          <p className="text-center">ls</p>
          <div className="flex items-center gap-[10px] my-[15px] border rounded-[10px] px-[7px] py-[7px] w-fit">
            <div
              onMouseEnter={(e: any) => handleMouseEnter('save-ls-list', e)}
              onMouseLeave={handleMouseLeave}
              onClick={saveListToLocalStorage}
              className="bg-[black] text-white rounded-full w-6 h-6 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faSave} />
            </div>
            <div
              onMouseEnter={(e: any) => handleMouseEnter('upload-ls-list', e)}
              onMouseLeave={handleMouseLeave}
              onClick={combineWithLocalStorageList}
              className="bg-[black] text-white rounded-full w-6 h-6 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faUpload} />
            </div>
            <div
              onMouseEnter={(e: any) => handleMouseEnter('empty-ls-list', e)}
              onMouseLeave={handleMouseLeave}
              onClick={emptyLocalStorageList}
              className="bg-gray-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </div>
        </div>
        <div>
          <p className="text-center">lc</p>
          <div className="flex items-center gap-[10px] my-[15px] border rounded-[10px] px-[7px] py-[7px] w-fit">
            <label
              htmlFor="lc-file-input"
              onMouseEnter={(e) => handleMouseEnter('upload-lc-list', e)}
              onMouseLeave={handleMouseLeave}
              className="bg-[black] text-white rounded-full w-6 h-6 flex items-center justify-center cursor-pointer"
            >
              <FontAwesomeIcon icon={faUpload} />
            </label>
            <input
              id="lc-file-input"
              type="file"
              accept=".xlsx, .xls"
              onChange={combineWithLocalComputerList}
              className="hidden"
            />
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto max-h-80">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-between items-center pb-3">
                <h2 className="text-2xl font-semibold">Saved Items</h2>
                <span
                  className="font-[700] text-[20px] modal-close cursor-pointer z-50"
                  onClick={toggleModal}
                >
                  X
                </span>
              </div>
              <ul>
                {savedItems.map((item, index) => (
                  <li key={item.id} className="py-4 border-b border-gray-300">
                    <p className="text-lg font-semibold">{item.title}</p>
                    <p className="text-gray-800">{item.overview}</p>
                    <p className="text-lg font-semibold">Question:</p>
                    <p className="ml-2 text-gray-800 bg-gray-100 rounded-md p-2">{item.question}</p>
                    <p className="text-lg font-semibold">Answer:</p>
                    <p className="ml-2 text-gray-800 bg-gray-100 rounded-md p-2">{item.answer}</p>
                    <p className="text-lg font-semibold">Rating:</p>
                    <p className="ml-2 text-gray-800 p-2">{item?.rating}</p>
                    <button
                      onClick={() => removeFromSavedItems(item.id)}
                      className="text-red-500 hover:text-red-700 focus:outline-none ml-2 mt-2"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-wrap gap-4">
        <input
          className="border border-gray-300 rounded-md px-4 py-2"
          type="text"
          placeholder="Search by description..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="bg-[black] text-white px-4 py-2 rounded-md"
          onClick={searchListByProperty}
        >
          Search
        </button>
        <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={getRandomObject}>
          Randomly Draw One Object
        </button>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-md"
          onClick={resetSelectedItem}
          disabled={!selectedItem}
        >
          Reset Picked Item
        </button>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-md"
          onClick={resetSearchResults}
          disabled={!searchResults.length}
        >
          Reset Search Results
        </button>
      </div>

      {selectedItem ? (
        <div className="mt-4 border border-gray-300 rounded-md p-4 bg-[#B7FFFA]">
          <div className="flex items-center gap-[5px]">
            <p className="font-[700]">{selectedItem.title}</p>
            <div
              // className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center"
              onClick={() => copyToClipboard(selectedItem.overview)}
            >
              <FontAwesomeIcon icon={faClipboard} className="mr-2" color={'#0D8131'} size={'xl'} />
              {/* Copy Overview */}
            </div>
          </div>

          <p>{selectedItem.overview}</p>

          <div className="mt-4 flex items-center">
            <input
              className="border border-gray-300 rounded-md px-4 py-2 w-full"
              type="text"
              placeholder="Ask a question..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap items-center gap-[10px] mt-[5px]">
            <button
              className="bg-[black] text-white px-4 py-2 rounded-md"
              onClick={findAnswer}
              disabled={findingAnswerStatus}
            >
              {findingAnswerStatus ? <>...</> : <>Find</>}
            </button>
            <button className="bg-[yellow] text-black px-4 py-2 rounded-md" onClick={continuePage}>
              Continue
            </button>
            <button className="bg-[red] text-white px-4 py-2 rounded-md" onClick={restartPage}>
              Restart
            </button>
          </div>
          {findingAnswerStatus ? (
            <>
              <div className="flex justify-center items-center m-[10px]">
                <div
                  className="w-[35px] h-[35px] border-[7px] border-t-0 rounded-full animate-spin"
                  style={{ borderColor: 'transparent transparent #FF3D00 transparent' }}
                ></div>
              </div>
            </>
          ) : (
            <>
              {findingAnswerError ? (
                <>
                  {answer && (
                    <div className="mt-4 border border-gray-300 rounded-md p-4 bg-[#F994EB]">
                      <p>에러: 죄송합니다. 오류가 발생하여 다시 시작해주십시오.</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {answer && (
                    <div className="mt-4 border border-gray-300 rounded-md p-4 bg-[#FFE867]">
                      <p>{answer}</p>
                      <button
                        onClick={() => addToSavedItems()}
                        className="mt-[5px] bg-[black] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Add to List
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      ) : null}

      {searchResults.length > 0 && (
        <div className="mt-4">
          <h2 className="text-lg font-bold">Search Results</h2>
          {currentResults.map((item: any) => (
            <div
              key={item.id}
              className="border border-gray-300 rounded-md p-4 mt-2 cursor-pointer"
              onClick={() => handleSelectItem(item)}
            >
              <p className="font-[700]">{item.title}</p>
              <p>{item.overview}</p>
            </div>
          ))}
          <div className="flex justify-center mt-4 items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              className="mx-1 px-4 py-2 border rounded-md bg-white text-blue-500"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <input
              type="text"
              value={pageInput}
              onChange={handlePageInputChange}
              className="mx-1 px-2 py-1 border rounded-md w-20 text-center"
            />
            <span className="mx-0">{'/ ' + totalPages}</span>
            <button
              onClick={handlePageInputSubmit}
              className="mx-1 px-4 py-2 border rounded-md bg-blue-500 text-white"
            >
              Go
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              className="mx-1 px-4 py-2 border rounded-md bg-white text-blue-500"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const RightComp = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [uploadedItems, setUploadedItems] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [inputPage, setInputPage] = useState<number | string>(1);
  const [showUnratedList, setShowUnratedList] = useState(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0] as string[];
      const items = jsonData.slice(1).map((row: any) => {
        const item: any = {};
        headers.forEach((header, index) => {
          item[header] = row[index];
        });
        return item;
      });

      setUploadedItems(items);
    };

    reader.readAsArrayBuffer(file);
  };

  const handlePageChange = (direction: 'prev' | 'next' | 'input') => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setInputPage(currentPage - 1);
    } else if (
      direction === 'next' &&
      currentPage < Math.ceil(uploadedItems.length / itemsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
      setInputPage(currentPage + 1);
    } else if (direction === 'input') {
      const pageNumber = Number(inputPage);
      if (pageNumber >= 1 && pageNumber <= Math.ceil(uploadedItems.length / itemsPerPage)) {
        setCurrentPage(pageNumber);
      }
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedItems = uploadedItems.slice(startIndex, startIndex + itemsPerPage);

  const openModal = (item: any) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };

  const handleRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedItem) {
      const updatedItem = { ...selectedItem, rating: e.target.value };
      setSelectedItem(updatedItem);
      setUploadedItems((prevItems) =>
        prevItems.map((item) => (item.id === selectedItem.id ? updatedItem : item))
      );
    }
  };

  const downloadAsExcel = () => {
    if (window.confirm('Download Excel file?')) {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(uploadedItems);

      const header = ['id', 'title', 'overview', 'question', 'answer', 'rating'];
      XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'UploadedItems');

      const date = new Date();
      const timestamp = date.toISOString().replace(/[-:]/g, '').replace(/\..*$/, '');
      XLSX.writeFile(workbook, `uploaded_items_${timestamp}.xlsx`);
    }
  };

  // Function to toggle display of unrated items list
  const toggleUnratedList = () => {
    setShowUnratedList(!showUnratedList);
  };

  // Filter unrated items
  const unratedItems = uploadedItems.filter((item) => !item.rating);

  // State variables for unrated items list pagination
  const [unratedCurrentPage, setUnratedCurrentPage] = useState(1);
  const [unratedInputPage, setUnratedInputPage] = useState<number | string>(1);

  // Function to handle pagination change for unrated items list
  const handleUnratedPageChange = (direction: 'prev' | 'next' | 'input') => {
    if (direction === 'prev' && unratedCurrentPage > 1) {
      setUnratedCurrentPage(unratedCurrentPage - 1);
      setUnratedInputPage(unratedCurrentPage - 1);
    } else if (
      direction === 'next' &&
      unratedCurrentPage < Math.ceil(unratedItems.length / itemsPerPage)
    ) {
      setUnratedCurrentPage(unratedCurrentPage + 1);
      setUnratedInputPage(unratedCurrentPage + 1);
    } else if (direction === 'input') {
      const pageNumber = Number(unratedInputPage);
      if (pageNumber >= 1 && pageNumber <= Math.ceil(unratedItems.length / itemsPerPage)) {
        setUnratedCurrentPage(pageNumber);
      }
    }
  };

  const [mos, setMOS] = useState<number | null>(null); // Mean Opinion Score
  const [confidenceInterval, setConfidenceInterval] = useState<[number, number] | null>(null); // Confidence Interval

  // Function to calculate MOS and confidence interval
  const calculateMetrics = () => {
    // Check if any item has no rating

    if (uploadedItems?.length <= 0) {
      alert('upload a file!');
      return;
    }

    if (
      uploadedItems.some(
        (item) => item?.rating === undefined || item?.rating === null || item?.rating?.length <= 0
      )
    ) {
      alert('Some items have no ratings. Please rate all items before calculating metrics.');
      return;
    }

    // Extract ratings from the list of items
    const ratings = uploadedItems.map((item) => parseInt(item.rating));

    // Calculate Mean Opinion Score (MOS)
    const sum = ratings.reduce((acc, rating) => acc + rating, 0);
    const meanRating = sum / ratings.length;
    setMOS(meanRating);

    // Bootstrap Confidence Interval
    const nBootstrapSamples = 1000; // Number of bootstrap samples
    const mosSamples = [];

    // Perform bootstrapping
    for (let i = 0; i < nBootstrapSamples; i++) {
      const bootstrapSample = [];
      for (let j = 0; j < ratings.length; j++) {
        const randomIndex = Math.floor(Math.random() * ratings.length);
        bootstrapSample.push(ratings[randomIndex]);
      }
      const bootstrapMOS =
        bootstrapSample.reduce((acc, rating) => acc + rating, 0) / bootstrapSample.length;
      mosSamples.push(bootstrapMOS);
    }

    // Calculate confidence interval
    const alpha = 0.95; // Confidence level
    const lowerQuantile = (1 - alpha) / 2;
    const upperQuantile = 1 - lowerQuantile;
    const sortedMOS = mosSamples.sort((a, b) => a - b);
    const lowerIndex = Math.floor(lowerQuantile * nBootstrapSamples);
    const upperIndex = Math.floor(upperQuantile * nBootstrapSamples);
    const confidenceInterval: [number, number] = [sortedMOS[lowerIndex], sortedMOS[upperIndex]];
    setConfidenceInterval(confidenceInterval);
  };

  return (
    <>
      <div className="p-4 bg-white rounded-md shadow-md mb-6">
        <div className="flex items-center justify-center gap-[10px]">
          <label className="flex items-center justify-center px-6 py-3 bg-blue-500 text-white rounded-md cursor-pointer hover:bg-blue-600">
            <FontAwesomeIcon icon={faUpload} className="h-6 w-6 mr-2" />
            <span>Browse</span>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              className="hidden"
            />
          </label>
          {uploadedItems.length > 0 && (
            <button
              className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600"
              onClick={downloadAsExcel}
            >
              <FontAwesomeIcon icon={faDownload} className="h-6 w-6 mr-2" />
              <span>Download</span>
            </button>
          )}
        </div>
      </div>

      {showUnratedList && unratedItems.length > 0 && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-md shadow-md w-3/4 max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Unrated Items ({unratedItems.length})</h2>
              <button
                onClick={toggleUnratedList}
                className="text-red-500 text-[20px] font-[700] hover:text-red-700 focus:outline-none"
              >
                X
              </button>
            </div>
            <div>
              <ul>
                {unratedItems
                  .slice((unratedCurrentPage - 1) * itemsPerPage, unratedCurrentPage * itemsPerPage)
                  .map((item, index) => (
                    <li key={index}>
                      {item.title} (Page{' '}
                      {Math.ceil((uploadedItems.indexOf(item) + 1) / itemsPerPage)})
                    </li>
                  ))}
              </ul>
            </div>
            <div className="flex justify-center mt-4">
              <button
                className="mr-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                onClick={() => handleUnratedPageChange('prev')}
                disabled={unratedCurrentPage === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <input
                type="number"
                value={unratedInputPage}
                onChange={(e) => setUnratedInputPage(e.target.value)}
                onBlur={() => handleUnratedPageChange('input')}
                className="mx-2 px-2 py-1 border rounded-md w-16"
                min="1"
                max={Math.ceil(unratedItems.length / itemsPerPage)}
              />
              <button
                className="px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                onClick={() => handleUnratedPageChange('input')}
              >
                Go
              </button>
              <button
                className="ml-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
                onClick={() => handleUnratedPageChange('next')}
                disabled={unratedCurrentPage === Math.ceil(unratedItems.length / itemsPerPage)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      )}

      {uploadedItems.length > 0 && (
        <div className="p-4 bg-white rounded-md shadow-md">
          <div className="flex justify-between my-[10px]">
            <div>Total items: {uploadedItems.length}</div>
            <div>
              {/* Icon to display unrated items count and list */}
              <div className="relative inline-block" onClick={toggleUnratedList}>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="xl"
                  className="text-[black] cursor-pointer"
                />
                {unratedItems.length > 0 && (
                  <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                    {unratedItems.length}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Title</th>
                  <th className="border px-4 py-2">Overview</th>
                  <th className="border px-4 py-2">Question</th>
                  <th className="border px-4 py-2">Answer</th>
                  <th className="border px-4 py-2">Rating</th>
                  <th className="border px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedItems.map((item, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2 truncate max-w-[100px]">{item.id}</td>
                    <td className="border px-4 py-2 truncate max-w-[100px]">{item.title}</td>
                    <td className="border px-4 py-2 truncate max-w-[200px]">{item.overview}</td>
                    <td className="border px-4 py-2 truncate max-w-[150px]">{item.question}</td>
                    <td className="border px-4 py-2 truncate max-w-[200px]">{item.answer}</td>
                    <td className="border px-4 py-2 truncate max-w-[100px]">{item.rating}</td>
                    <td className="border px-4 py-2">
                      <button
                        className="text-blue-500 hover:text-blue-700 focus:outline-none"
                        onClick={() => openModal(item)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-center items-center mt-4">
            <button
              className="mr-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
              onClick={() => handlePageChange('prev')}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <input
              type="number"
              value={inputPage}
              onChange={(e) => setInputPage(e.target.value)}
              onBlur={() => handlePageChange('input')}
              className="mx-2 px-2 py-1 border rounded-md w-16"
              min="1"
              max={Math.ceil(uploadedItems.length / itemsPerPage)}
            />
            <button
              className="px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
              onClick={() => handlePageChange('input')}
            >
              Go
            </button>
            <button
              className="ml-2 px-3 py-1 rounded-md bg-blue-500 text-white focus:outline-none hover:bg-blue-600"
              onClick={() => handlePageChange('next')}
              disabled={currentPage === Math.ceil(uploadedItems.length / itemsPerPage)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      )}
      {/* Modal for detailed view */}
      {showModal && selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-md shadow-md w-3/4 max-w-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Item Details</h2>
              <button
                onClick={closeModal}
                className="text-red-500 text-[20px] font-[700] hover:text-red-700 focus:outline-none"
              >
                X
              </button>
            </div>
            <div>
              <p>
                <strong>ID:</strong> {selectedItem.id}
              </p>
              <p>
                <strong>{selectedItem.title}</strong>
              </p>
              <p>{selectedItem.overview}</p>
              <div className="bg-gray-100 p-2 rounded-md mb-2">
                <p className="font-semibold">Question:</p>
                <p>{selectedItem.question}</p>
              </div>
              <div className="bg-gray-100 p-2 rounded-md mb-2">
                <p className="font-semibold">Answer:</p>
                <p>{selectedItem.answer}</p>
              </div>
              <div className="mb-2">
                <label className="font-semibold">Rating:</label>
                <input
                  type="number"
                  value={selectedItem.rating}
                  onChange={handleRatingChange}
                  className="ml-2 px-2 py-1 border rounded-md w-16"
                  min="1"
                  max="5"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center items-center flex-col mt-[10px]">
        {/* Button to trigger calculation */}
        <button
          onClick={calculateMetrics}
          className="bg-[black] text-[white] rounded-[7px] py-[5px] px-[5px]"
        >
          Calculate MOS and Bootstrap Confidence Interval
        </button>

        {/* Display MOS and confidence interval */}
        {mos !== null && confidenceInterval !== null && (
          <div>
            {/* Display Mean Opinion Score (MOS) */}
            <p>
              <strong>Mean Opinion Score (MOS):</strong> {mos.toFixed(2)}
              {/* Explanation of MOS */}
              <span className="text-gray-500"></span>
            </p>
            {/* Display Bootstrap Confidence Interval */}
            <p>
              <strong>Bootstrap Confidence Interval:</strong> [{confidenceInterval[0].toFixed(2)},{' '}
              {confidenceInterval[1].toFixed(2)}]{/* Explanation of Confidence Interval */}
              <span className="text-gray-500"></span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const Test6 = ({ vkTourDests }: any) => {
  return (
    <div className="flex">
      <div className="w-1/2 p-4 border-r border-gray-300">
        <LeftComp vkTourDests={vkTourDests ?? []} />
      </div>
      <div className="w-1/2 p-4">
        <RightComp />
      </div>
    </div>
  );
};

export default Test6;
